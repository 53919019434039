import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/var/jenkins_home/workspace/affilate_mono_repo_vilkencykel/gatsby/src/components/mdxLayout.js";
import { Link } from "gatsby";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "vilken-cykel"
    }}>{`Vilken Cykel`}</h1>
    <p>{`Välkommen till Vilken Cykel! Vi som har skapat denna sidan gjorde det då
vi själva upplevde att det var en djungel att hitta en elcykel som
passade just oss. De flesta butiker har sina egna märken vilket gör det
svårt att jämföra olika modeller. Vårt mål med Vilken Cykel är att vi
ska kunna lista så många elcyklar som möjligt för att du som kund ska ha
en enda portal att leta på när du ska köpa en ny elcykel. Utöver detta
bygger vi ständigt på ny funktionaltiet för att du enklare ska kunna
filtrera och söka bland alla cyklar vi har i vår databas.`}</p>
    <p>{`Observera att vi inte säljer några cyklar själva, istället hämtar vi
information från Svenska ebutiker och listar de elcyklar vi hittar. Vi
försöker även plocka ut de egenskaper som vi tycker är mest relevant vid
ett köp, och låter dig som användare filtrera på dessa. Sidan är
fortfarande under uppbyggnad vilket betyder att mängder med fler
egenskaper på cyklarna kommer läggas till och fler funktioner kommer
dyka upp på sidan. Har du några frågor, funderingar eller bara ideér på
vad du saknar på sidan? Tveka då inte att höra av dig till `}<a href="mailto:info@vilkencykel.se"><a parentName="p" {...{
          "href": "mailto:info@vilkencykel.se"
        }}>{`info@vilkencykel.se`}</a></a>{`.`}</p>
    <h2 {...{
      "id": "cyklar"
    }}>{`Cyklar`}</h2>
    <p>{`Under fliken `}<Link to="/cyklar/" mdxType="Link">{`Cyklar`}</Link>{` hittar du alla cyklar vi har
i vår databas. För närvarande fokuserar vi enbart på elcyklar.`}</p>
    <h2 {...{
      "id": "guider"
    }}>{`Guider`}</h2>
    <p>{`På `}<Link to="/guider/" mdxType="Link">{`Guider`}</Link>{` är målet att vi ska lägga upp guider och liknande
som ska hjälpa dig att reda ut alla begrepp och ge dig den kunskap du behöver för
att hitta just den cykeln som passar dig.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      